<template>
  <form @submit.prevent="addEmail" class="email col container">
    <div class="form-row form-group my-2 justify-content-between">
      <h5>Emails</h5>
      <add-button
        @click="addEmail"
        type="submit"
        v-tooltip="'Press Alt+A while in this form to add an email.'"
      />
    </div>
    <div class="emails-main">
      <div
        v-for="(entry, index) in allEmails"
        :key="index"
        class="form-row d-flex align-items-center"
      >
        <label class="col-2" :for="entry.type">
          <b>{{ index === 0 ? "Primary" : "Alternate" }}</b>
        </label>
        <text-input
          :noLabel="true"
          :validator="v && v.$each[index].email"
          :validatorMsgMap="validatorMsgMap"
          v-model="entry.email"
          name="email"
          class="col mt-3"
          :required="true"
          data-private="redact"
          :tabindex="tabProp || 0"
        />
        <div class="d-flex justify-content-between">
          <button v-if="!noPrimary" @click="setPrimary(index)" type="button" class="btn">
            <template v-if="entry.isPrimary">
              <icon class="text-primary" icon="parking" />
            </template>
            <template v-else>
              <span class="secondary-icon">S</span>
            </template>
          </button>
          <icon-button
            type="button"
            @click="removeEmail(index, entry)"
            class="btn text-danger"
            icon="trash-alt"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { validatorMsgMapBase, isIE } from "@/modules/helpers.js";
import shortcutKey from "@/mixins/shortcutKeys.js";
import AddButton from "../common/AddButton.vue";
import Icon from "../common/Icon.vue";
import IconButton from "../common/IconButton.vue";
import TextInput from "../common/TextInput.vue";
export default {
  components: { AddButton, Icon, IconButton, TextInput },
  name: "Emails",
  props: {
    value: {
      required: true
    },
    v: {
      type: Object
    },
    noPrimary: {
      default() {
        return false;
      }
    },
    tabProp: {
      type: Number
    }
  },
  mixins: [shortcutKey({ keyCode: 65, modifier: "alt" }, "addEmail")],
  computed: {
    emails: {
      get() {
        return this.value;
      },
      set(value) {
        value.forEach(email => {
          if (email.email) {
            email.address = email.email;
          }
        });
        this.$emit("input", value);
      }
    },
    allEmails() {
      return this.emails?.filter(e => !e?.isDeleted) || [];
    },
    validatorMsgMap() {
      return validatorMsgMapBase;
    }
  },
  methods: {
    addEmail() {
      if (!this.value.length || this.$el.checkValidity()) {
        const emails = [...this.value, { email: "", address: "", isPrimary: false }];
        if (this.value.length === 0) {
          emails[0].isPrimary = true;
        }
        this.$emit("input", emails);
      } else if (!isIE()) {
        this.$el.reportValidity();
      }
    },
    setPrimary(i) {
      const emails = this.value.map((e, j) => {
        if (Number(i) === j) {
          e.isPrimary = true;
        } else {
          e.isPrimary = false;
        }
        return e;
      });
      this.$emit("input", emails);
    },
    removeEmail(i, entry) {
      if (entry.id) {
        const emails = this.value.map(e => {
          if (e.id === entry.id) {
            e.isDeleted = true;
          }
          return e;
        });
        this.$emit("input", emails);
      } else if (this.value.length) {
        const emails = [...this.value.slice(0, i), ...this.value.slice(i + 1)];
        this.$emit("input", emails);
      }
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss" scoped></style>
